import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.scss"
import classnames from "../../helpers/classnames"

function Section({
                   title,
                   titleCustom,
                   children,
                   noPad,
                   className,
                   classNameContent,
                  grey=false,
                   ...rest
                 }) {
  return (
    <section
      className={classnames(
        className,
        styles.section,
        noPad ? styles.sectionNoPad : null,
        grey ? styles.sectionGrey : null,
      )}
      {...rest}
    >
      <div className={styles.section__wrapper}>
        {title &&
        <h2 className={styles.title}>{title}</h2>
        }
        {titleCustom}
        <div className={classnames(
          classNameContent,
          styles.contentWrapper
        )}>
          {children}
        </div>
      </div>
    </section>
  )
}

export default Section
