
/**
 * Sends Email in both 'text', 'html' versions
 * to 'to' parameter.
 * Param list: { site, subject, text, html, from }
 * @param {*} param0 
 */
export function sendEmail({ site, subject, text, html, from, to }) {
  const API_URL = 'https://europe-west3-mindworxacademy.cloudfunctions.net/sendEmail'

  const body = JSON.stringify({
    site, subject, text, html, from, to
  })

  return new Promise((resolve, reject) => {
    fetch(API_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    .then(res => res.json())
    .then(json => {
      resolve(json)
    })
    .catch(err => {
      reject(err)
    })
  }) 
}
