import React, { Component } from 'react'
import Section from "../components/Section"
import MainLayout from "../components/Layout/MainLayout"
import sectionStyle from "../components/Section/styles.module.scss"
import styles from "../styles/page-experience.module.scss"

import Logo from "../components/logo"
import Row from "../components/Row"
import certificateImg from "../images/course-services/sm-laptop-certificate.png"
import ratingImg from "../images/course-services/sm-laptop-rating.jpg"
// PRICING
import stylesPricing from "../components/pricing/pricing.module.scss"
import Button from "../components/button"
import classnames from "../helpers/classnames"

import Popup from '../components/popup'
import { Link } from 'gatsby';
import { validateEmail } from '../lib/validators'
import { sendEmail } from "../lib/sendEmail"
import modalStyles from "../components/popup/popup.module.scss"

// custom footer
import customFooterStyles from '../components/footer/footer.module.css';


// seo
import SEO from '../components/seo'
import CookieSimple from '../components/cookie/cookieSimple'
import "../styles/colors.scss"
import "../styles/responsive.scss"
import "../styles/global.scss"


const cardStyle = {
  flex: '1 0 50%',
  // border: '1px solid #dedede',
  // boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.12)',
  padding: '16px',
  // margin: '0 4px',
  textAlign: 'center',
  fontSize: '25px',
  fontWeight: 'bold',
  maxWidth: '430px'
}

class CourseServices extends Component {
  state = {
    contactPopupShow: false,
    purchaseType: null
  }

  render() {
    return (
      <div>
        <SEO />
        <div className="topWrapper" style={{ backgroundImage: 'url(/course-services-bcg.jpg)', maxHeight: '600px' }}>
          <div className="topContent">
            <div className="logoWrapper" style={{ display: 'none' }}>
              <a href="/#" title="MINDWORX" className="logo">
                <Logo
                  style={{ width: "100%", height: "100%" }}
                  type={"white"}
                />
              </a>
            </div>
            <h1 className="topTitles_main" style={{ paddingTop: '60px' }}>
              Tools to power your Teachable course
              </h1>
            <h2 className="topTitles_text">
              Enable learners to <strong>rate</strong> your course, give you feedback and <strong>share their certificates</strong> online
              </h2>
            <div className="topButtonWrapper">
              <Button id="saas-learn-more" size="ebooksBig" onClick={() => {
                if (typeof window !== "undefined") {
                  const r = document.getElementById('certificate').getBoundingClientRect();
                  window.scrollTo({
                    left: 0,
                    top: r ? r.top : 1000,
                    behavior: "smooth",
                  })
                }

              }}>
                {" "}
                  LEARN MORE
                </Button>
            </div>
          </div>
        </div>


        <div style={{ paddingTop: "120px", display: 'none' }}>
          <Section
            titleCustom={
              <h1
                className={sectionStyle.title + ` ${styles.masterclassTitle}`}
              >Tools to power your Teachable course
            <div style={{ marginTop: '16px', color: '#011499', fontSize: '27px', fontWeight: 'lighter' }}>
                  Enable learners to <strong>rate</strong> and your course, give you feedback and <strong>share their certificates</strong> online
                </div>
              </h1>
            }
            className={styles.masterclassSection}
          >
          </Section>
        </div>

        <div>

          <Section id="certificate" className="bg-grey-none">
            <Row style={{ flexFlow: 'column' }}>
              <div className={styles.rowTextWrapper} style={{ maxWidth: '750px', margin: '0 auto' }}>
                <h2>Rating and Feedback</h2>
                <p style={{ textAlign: 'center' }}>
                  Enable students to rate your course and give you feedback directly inside Teachable. You simply choose the chapters where a "Rate us" window will appear. You will thus get valuable feedback, testimonials and rating which you can show to your potential customers as testimonials.
                </p>
              </div>
              <div className={styles.rowImageWrapper}>
                <img src={ratingImg} alt="certificate example" />
              </div>
            </Row>
          </Section>


        </div>


        <div>

          <Section id="certificate" className="" style={{ background: '#011499', color: '#fff' }}>
            <Row style={{ flexFlow: 'column' }}>
              <div className={styles.rowTextWrapper} style={{ maxWidth: '750px', margin: '0 auto' }}>
                <h2>Shareable Certificates</h2>
                <p style={{ textAlign: 'center' }}>
                  Make it easier for students to share their certificates on social media. You will get more visibility and more traffic.
                  We'll automatically create unique URL links to certificates that students will receive when they finish the course.
                </p>
              </div>
              <div className={styles.rowImageWrapper}>
                <img src={certificateImg} alt="certificate example" />
              </div>
            </Row>
          </Section>

        </div>

        <div>

          <Section id="certificate" className="">
            <Row style={{ flexFlow: 'column' }}>
              <div className={styles.rowTextWrapper} style={{ maxWidth: '750px', margin: '0 auto' }}>
                <h2>Installation &amp; Setup</h2>
                <p style={{ textAlign: 'center' }}>
                  It's a simple plug & play. We'll tell you exactly what's needed and we'll personally guide you through the installation process in 5 minutes.
                </p>
              </div>
            </Row>
          </Section>
          <hr style={{ border: '1px solid #f1f1f1', maxWidth: '750px', margin: '0 auto' }} />

        </div>

        <div>

          <Section
            id="pricing"
            title={<div style={{ lineHeight: 1 }}>Simple pricing<br /><span style={{ fontSize: '20px', fontWeight: '400' }}>No additional costs or fees</span></div>}
            className={styles.studentInterfaceSection + `sm:mt-0`}
            style={{ paddingTop: '0', marginTop:'60px' }}
          >

            <PricingComponent
              buyButtonCallback={({ type }) => {
                this.setState({
                  contactPopupShow: true,
                  purchaseType: type
                })
              }}
              styles={stylesPricing} />
          </Section>

          <CustomFooter />
          <ContactPopup
            purchaseType={this.state.purchaseType}
            handleClose={() => { this.setState({ contactPopupShow: false }) }}
            show={this.state.contactPopupShow} />
        </div>

        <CookieSimple dontLoadDrift />
      </div>
    )
  }
}


export default CourseServices

const PricingComponent = (props) => {
  const { styles, buyButtonCallback } = props
  return (
    <div className={styles.cardsList} id="pricing" >

      {/* <div className={classnames(
        styles.card,
        styles.cardBig,
        styles.cardMoneybackGuarantee
      )}>
        <h3 className={styles.card__title}>
          Complete Masterclass
            </h3>

        <ul className={styles.card__text}>
          <li>
            <strong>8h video lectures </strong>
            <br />
                (English & Portuguese subs)
              </li>
          <li>
            <strong>14 eBooks</strong> (in total 450+ pages)
              </li>
          <li>
            <strong>Ideation prompts</strong>
          </li>
          <li>
            <strong>Certificate</strong>
          </li>
        </ul>
        <h4 className={styles.card__price}>
          Custom price for 5+ licenses
            </h4>
        <button
          id="main-custom-contact"
          className={styles.card__btn}
          onClick={() => {}}
          title=""
          type="button"
        >
          CONTACT US
            </button>
      </div> */}

      <div id="pricing-complete-masterclass" style={{ minHeight: '360px' }} className={classnames(
        styles.card,
      )}>
        <h3 className={styles.card__title}>
          Rating and Feedback
        </h3>

        <ul className={styles.card__text}>

          <li><strong style={{ fontSize: '12px' }}>UNLIMITED</strong> Feedback and Rating submissions</li>
          <li>Daily Feedback and Rating reports</li>
          <li>Technical support during installation</li>
        </ul>

        <div style={{ marginTop: 'auto' }}>
          <h4 className={styles.card__newPrice} style={{ color: 'inherit', lineHeight: 1, marginTop: '8px' }}>
            $8.98<br />
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>(Billed monthly)</span>
          </h4>
          <Button
            onClick={() => buyButtonCallback({ type: 'rating_and_feedback' })}
            id="saas-buy"
            target="_blank"
            style={{ width: "200px", marginTop: "30px" }}
            border
          >
            Try 14 days Free
              </Button>
        </div>
      </div>

      <div id="pricing-complete-masterclass" style={{ minHeight: '420px' }} className={classnames(
        styles.card,
        styles.cardBig,
        styles.cardOutlined,
      )}>
        <h3 className={styles.card__title}>
          Complete Bundle
        </h3>

        <ul className={styles.card__text}>

          <li><strong style={{ fontSize: '12px' }}>UNLIMITED</strong> Shareable certificates</li>
          <li><strong style={{ fontSize: '12px' }}>UNLIMITED</strong> Feedback and Rating submissions</li>
          <li>Daily Feedback and Rating reports</li>
          <li>Technical support during installation</li>
        </ul>

        <div style={{ marginTop: 'auto' }}>
          <h4 className={styles.card__newPrice} style={{ color: 'inherit', lineHeight: 1, marginTop: '8px' }}>
            $17.98<br />
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>(Billed monthly)</span>
          </h4>
          <Button
            onClick={() => buyButtonCallback({ type: 'complete' })}
            id="saas-buy"
            target="_blank"
            style={{ width: "200px", marginTop: "30px" }}
            border
          >
            Try 14 days Free
              </Button>
        </div>
      </div>

      <div id="pricing-complete-masterclass" style={{ minHeight: '360px' }} className={classnames(
        styles.card,
      )}>
        <h3 className={styles.card__title}>
          Shareable Certificates
        </h3>

        <ul className={styles.card__text}>

          <li><strong style={{ fontSize: '12px' }}>UNLIMITED</strong> Shareable certificates</li>
          <li>Technical support during installation</li>
        </ul>

        <div style={{ marginTop: 'auto' }}>
          <h4 className={styles.card__newPrice} style={{ color: 'inherit', lineHeight: 1, marginTop: '8px' }}>
            $15<br />
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>(Billed monthly)</span>
          </h4>
          <Button
            onClick={() => buyButtonCallback({ type: 'sharable_certificates' })}
            id="saas-buy"
            target="_blank"
            style={{ width: "200px", marginTop: "30px" }}
            border
          >
            Try 14 days Free
              </Button>
        </div>
      </div>


      {/* <div className={styles.card}>
        <h3 className={styles.card__title}>eBooks</h3>
        <ul className={styles.card__text}>
          <li>
            <strong>14 eBooks </strong>(in total 450+ pages)
              </li>
          <li>
            <strong>Ideation prompts</strong>
          </li>
        </ul>

        <h4 className={styles.card__price}>1</h4>
        <a
          className={styles.card__btn}
          href={'#'}
          id="main-ebooks-buy"
          target="_blank"
          title=""
        >
        ENROLL NOW
            </a>
      </div>
     */}
    </div >
  )
}


class ContactPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '', // form field
      message: '', // form field
      tel: '', // form field
      buttonText: 'Submit', // form submit button text
      buttonDisabled: false,
      isPolicyAccepted: false, // form field

      feedbackMessage: ''
    }

    this.isFormValid = this.isFormValid.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  /**
   * Returns bool,
   * every field must be filled in form to be valid.
   */
  isFormValid() {
    const { email, isPolicyAccepted } = this.state
    return isPolicyAccepted && validateEmail(email)
  }

  handleFormSubmit(e) {
    e.preventDefault()

    const emailHTML = `
      <b>Purchase type:</b> ${this.purchaseTypeText(this.props.purchaseType)}<br>
      <b>Email:</b> ${this.state.email}<br>
      <b>Tel:</b>: ${this.state.tel}<br>
      <b>Message:</b> ${this.state.message}<br>
    `;
    const emailTXT = `
    - Purchase type: ${this.purchaseTypeText(this.props.purchaseType)} \n
    ---
    - Email: ${this.state.email}\n
    ---
    - Tel: ${this.state.tel}\n
    ---
    - Message: ${this.state.message}\n
    `

    this.setState({ buttonText: 'Processing...' })

    // Sends composed email
    sendEmail({
      to: "saas@mindworxacademy.com",
      site: "mindworxacademy.com",
      subject: `SaaS - Request For ${this.purchaseTypeText(this.props.purchaseType)} service`,
      text: emailTXT,
      html: emailHTML
    })
      .then(_ => {
        this.setState({ feedbackMessage: 'Thank you! We’ll get back to you soon.' })
        this.setState({ buttonText: 'Submit', buttonDisabled: true })
      })
      .catch(_ => {
        this.setState({ feedbackMessage: 'Error has occurred, please check all fields and try submitting again.' })
        this.setState({ buttonText: 'Submit', buttonDisabled: false })
      })
  }

  purchaseTypeText(type) {
    if (type === 'rating_and_feedback') {
      return 'Rating and Feedback'
    } else if (type === 'complete') {
      return 'Complete Bundle'
    } else if (type === 'sharable_certificates') {
      return 'Shareable Certificates'
    }
  }

  render() {
    return (
      <Popup show={this.props.show} handleClose={this.props.handleClose}>
        <h2 className={modalStyles.title}>
          Thanks for your interest
        </h2>
        <p className={modalStyles.text}>
          Let's get you started.<br />
          We'll get back to you quickly.
        </p>

        <form
          className={modalStyles.form}
          // action="/newsletter/subscribe-plus"
          method="post"
          // noValidate
          onSubmit={this.handleFormSubmit}
        >
          <input
            className={modalStyles.inputText}
            name="email"
            placeholder="Your email (required)"
            autoComplete="off"
            type="email"
            required
            onChange={e => this.setState({ email: e.target.value })}
          />
          <input
            className={modalStyles.inputText}
            name="tel"
            placeholder="Your phone number (not required)"
            autoComplete="off"
            type="tel"
            onChange={e => this.setState({ tel: e.target.value })}
          />
          <textarea
            placeholder="Your message (not required)"
            onChange={e => this.setState({ message: e.target.value })}
            className={modalStyles.inputText}
            rows={2}></textarea>

          <div className={modalStyles.checkboxWrapper}>
            <input
              type="checkbox"
              className={modalStyles.checkboxInput}
              id="contact-popup-checkbox"
              onChange={_ => {
                this.setState(state => ({
                  isPolicyAccepted: !state.isPolicyAccepted,
                }))
              }}
            />

            <label
              htmlFor="contact-popup-checkbox"
              style={{
                fontSize: '12px',
                paddingLeft: '28px',
                fontWeight: '400',
              }}
            >
              {' '}
              I agree to the
              <Link className={modalStyles.link} to="/privacy-policy">
                privacy policy terms.
              </Link>
            </label>
          </div>

          <div>
            <small>
              Selected service: {this.purchaseTypeText(this.props.purchaseType)}
            </small>
          </div>

          <Button size="popup" type="submit" disabled={!this.isFormValid() || this.state.buttonDisabled}>{this.state.buttonText}</Button>

          <div className={modalStyles.message}>{this.state.feedbackMessage}</div>
        </form>


      </Popup>
    )
  }
}


const CustomFooter = () => {
  const styles = customFooterStyles
  return (
    <div className={styles.footerWrapper}>
      <footer className={styles.footer}>
        <div className={styles.listsWrapper}>
          <ul className={styles.list}>
            <li>
              e-mail:{' '}
              <a href={'mailto:info@mindworxacademy.com'}>
                info@mindworxacademy.com
              </a>
            </li>
          </ul>

          <ul className={styles.socialList}>
            <li>
              <a
                href="https://www.facebook.com/Mindworx-Academy-472812813233182"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mindworx_academy/ "
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/mindworxacademy/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
          </ul>

          <ul className={styles.listBottom}>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-of-use">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <a href="/#" title="MINDWORX" className={styles.logo} />
        <div>
          <Logo style={{ width: 'auto' }} type="white" />
        </div>

        <div className={styles.footerCopy}>
          ©2019 - 2020 MINDWORX Academy. All rights reserved.
        </div>
      </footer>
    </div>
  )
}