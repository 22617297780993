import React from "react"

import styles from "./styles.module.scss"
import classnames from "../../helpers/classnames"

function Row ({className, children, ...rest}) {
  return (
    <div
      className={classnames(className, styles.row)}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Row;
