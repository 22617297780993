import React from "react"
import PropTypes from "prop-types"

import styles from "./popup.module.scss"

const popupTransition = show => {
  return show ? styles.modalWrapper : styles.modalHidden
}

const Popup = ({ handleClose, show, children }) => {
  return (
    <>
      <div className={popupTransition(show)}>
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <div className={styles.buttonWrapper}>
              <button
                type="button"
                onClick={handleClose}
                className={styles.closeButton}
              >
                <span className={styles.icon}> &#10006;</span>
              </button>
            </div>
            <div className={styles.propsWrapper}>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

Popup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired,
}

export default Popup
